import { HttpCacheClient } from 'common/core/http/http-cache-client';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/http-cache-client";
export class Themes {
    /**
     * Themes API service constructor.
     */
    constructor(http) {
        this.http = http;
    }
    /**
     * Get all available themes.
     */
    all() {
        return this.http.get('themes');
    }
}
Themes.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Themes_Factory() { return new Themes(i0.ɵɵinject(i1.HttpCacheClient)); }, token: Themes, providedIn: "root" });
