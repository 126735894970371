var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Toast } from 'common/core/ui/toast.service';
import { Themes } from '../themes/themes.service';
import { Project } from '../projects/Project';
import { Projects } from '../projects/projects.service';
import { PageDocument } from '../page-document';
import { Templates } from '../templates/templates.service';
import { forkJoin } from 'rxjs';
import { randomString } from '@common/core/utils/random-string';
import { DomHelpers } from '../dom-helpers.service';
const DEFAULT_FRAMEWORK = 'bootstrap-3';
export class CrupdateProjectModalComponent {
    constructor(dialogRef, data, projects, toast, themesApi, templatesApi) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.projects = projects;
        this.toast = toast;
        this.themesApi = themesApi;
        this.templatesApi = templatesApi;
        this.themes = [];
        this.templates = [];
        this.loading = false;
        this.updating = false;
        this.errors = {};
        this.pageDocument = new PageDocument();
    }
    ngOnInit() {
        if (this.data.showExtraConfig) {
            this.getThemesAndTemplates();
        }
        this.hydrateModel();
        // this.pageDocument.setBaseUrl(this.projectUrl.getBaseUrl(this.model));
        this.updating = !!this.data.project;
    }
    confirm() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            let request;
            if (this.updating) {
                request = this.projects.update(this.data.project.id, this.model);
            }
            else {
                request = this.projects.create(yield this.getNewProjectPayload());
            }
            request.subscribe(response => {
                this.close(response.project);
                const action = this.updating ? 'updated' : 'created';
                this.toast.open('Project has been ' + action);
                this.loading = false;
            }, response => {
                this.errors = response.messages;
                this.loading = false;
            });
        });
    }
    close(data) {
        this.dialogRef.close(data);
    }
    hydrateModel() {
        this.errors = {};
        this.model = new Project({
            pages: [],
            template: this.data.templateName || null,
            framework: !this.data.templateName ? DEFAULT_FRAMEWORK : null,
            uuid: randomString(36)
        });
        if (this.data.project) {
            this.model.name = this.data.project.name;
            this.model.framework = this.data.project.framework;
            this.model.theme = this.data.project.theme;
        }
    }
    getThemesAndTemplates() {
        forkJoin(this.themesApi.all(), this.templatesApi.all()).subscribe(response => {
            this.themes = response[0].themes;
            this.templates = response[1].pagination.data;
        });
    }
    getNewProjectPayload() {
        const templateName = this.data.templateName || this.model.template;
        if (templateName) {
            return this.createProjectFromTemplate(templateName);
        }
        else {
            return this.createBlankProject();
        }
    }
    createProjectFromTemplate(templateName) {
        return new Promise(resolve => {
            const params = this.model;
            this.templatesApi.get(templateName).subscribe(response => {
                params.template = response.template;
                params.framework = response.template.config.framework;
                params.pages = this.transformTemplatePages(response.template);
                resolve(params);
            });
        });
    }
    createBlankProject() {
        const params = this.model;
        params.pages.push({
            name: 'index',
            html: DomHelpers.removeBaseUrl(this.pageDocument.generate().getOuterHtml()),
        });
        return params;
    }
    transformTemplatePages(template) {
        return template.pages.map(page => {
            return {
                name: page.name,
                html: DomHelpers.removeBaseUrl(this.pageDocument.generate(page.html, template).getOuterHtml()),
            };
        });
    }
}
