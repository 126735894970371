/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-results-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./no-results-message.component";
import * as i3 from "../../config/settings.service";
var styles_NoResultsMessageComponent = [i0.styles];
var RenderType_NoResultsMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoResultsMessageComponent, data: {} });
export { RenderType_NoResultsMessageComponent as RenderType_NoResultsMessageComponent };
export function View_NoResultsMessageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "secondary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), i1.ɵncd(null, 1), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings.getAssetUrl("images/no-results.svg"); _ck(_v, 2, 0, currVal_0); }); }
export function View_NoResultsMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "no-results-message", [["class", "no-results-message"]], [[2, "horizontal", null]], null, null, View_NoResultsMessageComponent_0, RenderType_NoResultsMessageComponent)), i1.ɵdid(1, 49152, null, 0, i2.NoResultsMessageComponent, [i3.Settings], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).horizontal; _ck(_v, 0, 0, currVal_0); }); }
var NoResultsMessageComponentNgFactory = i1.ɵccf("no-results-message", i2.NoResultsMessageComponent, View_NoResultsMessageComponent_Host_0, { horizontal: "horizontal" }, {}, ["[primary-text]", "[secondary-text]"]);
export { NoResultsMessageComponentNgFactory as NoResultsMessageComponentNgFactory };
