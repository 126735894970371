import { Routes } from '@angular/router';
import { GuestGuard } from 'common/guards/guest-guard.service';
import { ContactComponent } from '@common/contact/contact.component';
import { LandingComponent } from './dashboard/landing/landing.component';
const ɵ0 = () => import("./html-builder/html-builder.module.ngfactory").then(m => m.HtmlBuilderModuleNgFactory), ɵ1 = () => import("./admin/app-admin.module.ngfactory").then(m => m.AppAdminModuleNgFactory), ɵ2 = () => import("./../common/billing/billing.module.ngfactory").then(m => m.BillingModuleNgFactory);
const routes = [
    { path: '', component: LandingComponent, canActivate: [GuestGuard] },
    { path: 'builder', loadChildren: ɵ0 },
    { path: 'admin', loadChildren: ɵ1 },
    { path: 'billing', loadChildren: ɵ2 },
    { path: 'contact', component: ContactComponent },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
