<div class="icon">
    <img [src]="settings.getAssetUrl('images/no-results.svg')">
</div>
<div class="text">
    <div class="main">
        <ng-content select="[primary-text]"></ng-content>
    </div>
    <div class="secondary">
        <ng-content select="[secondary-text]"></ng-content>
    </div>
</div>