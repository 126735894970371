/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-data-table-inputs.component";
var styles_AppDataTableInputsComponent = [];
var RenderType_AppDataTableInputsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppDataTableInputsComponent, data: {} });
export { RenderType_AppDataTableInputsComponent as RenderType_AppDataTableInputsComponent };
export function View_AppDataTableInputsComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_AppDataTableInputsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-data-table-inputs", [], null, null, null, View_AppDataTableInputsComponent_0, RenderType_AppDataTableInputsComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppDataTableInputsComponent, [], null, null)], null, null); }
var AppDataTableInputsComponentNgFactory = i0.ɵccf("app-data-table-inputs", i1.AppDataTableInputsComponent, View_AppDataTableInputsComponent_Host_0, { formGroup: "formGroup", filter: "filter" }, {}, []);
export { AppDataTableInputsComponentNgFactory as AppDataTableInputsComponentNgFactory };
