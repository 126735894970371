import { makeUploadPayload } from '../core/utils/make-upload-payload';
import { AppHttpClient } from '../core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/http/app-http-client.service";
export class UploadsApiService {
    constructor(http) {
        this.http = http;
    }
    getFileContents(file) {
        return this.http.get('uploads/' + file.id, null, { responseType: 'text' });
    }
    delete(params) {
        return this.http.delete('uploads', params);
    }
    upload(file, config = {}) {
        return this.http.postWithProgress(config.uri || 'uploads', makeUploadPayload(file, config.httpParams));
    }
}
UploadsApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UploadsApiService_Factory() { return new UploadsApiService(i0.ɵɵinject(i1.AppHttpClient)); }, token: UploadsApiService, providedIn: "root" });
