import { AppHttpClient } from 'common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class Templates {
    constructor(http) {
        this.http = http;
    }
    /**
     * Get all available templates.
     */
    all(params = {}) {
        return this.http.get('templates', params);
    }
    /**
     * Get template by specified id.
     */
    get(name) {
        return this.http.get('templates/' + name);
    }
    /**
     * Create a new template.
     */
    create(params) {
        return this.http.post('templates', params);
    }
    /**
     * Update specified template.
     */
    update(name, params) {
        return this.http.put('templates/' + name, params);
    }
    /**
     * Delete specified templates.
     */
    delete(names) {
        return this.http.delete('templates', { names });
    }
}
Templates.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Templates_Factory() { return new Templates(i0.ɵɵinject(i1.AppHttpClient)); }, token: Templates, providedIn: "root" });
