import { Router } from '@angular/router';
import { CurrentUser } from 'common/auth/current-user';
import { Projects } from '../shared/projects/projects.service';
import { forkJoin } from 'rxjs';
import { CustomDomainService } from '@common/custom-domain/custom-domain.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../shared/projects/projects.service";
import * as i3 from "../../common/auth/current-user";
import * as i4 from "../../common/custom-domain/custom-domain.service";
export class DashboardResolver {
    constructor(router, projects, currentUser, domains) {
        this.router = router;
        this.projects = projects;
        this.currentUser = currentUser;
        this.domains = domains;
    }
    resolve(route, state) {
        return forkJoin([
            this.projects.all({ user_id: this.currentUser.get('id'), per_page: 30 }),
            this.domains.index({ userId: this.currentUser.get('id'), with: ['resource'] }),
        ]).pipe(map(data => {
            return { projects: data[0].pagination.data, domains: data[1].pagination.data };
        }));
    }
}
DashboardResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardResolver_Factory() { return new DashboardResolver(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Projects), i0.ɵɵinject(i3.CurrentUser), i0.ɵɵinject(i4.CustomDomainService)); }, token: DashboardResolver, providedIn: "root" });
