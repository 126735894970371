import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/http/app-http-client.service";
const BASE_URI = 'custom-domain';
export class CustomDomainService {
    constructor(http) {
        this.http = http;
    }
    index(params = {}) {
        return this.http.get(`${BASE_URI}`, params);
    }
    create(params) {
        return this.http.post(BASE_URI, params);
    }
    update(id, params) {
        return this.http.put(`${BASE_URI}/${id}`, params);
    }
    delete(ids) {
        return this.http.delete(`${BASE_URI}/${ids}`);
    }
    validate(host) {
        return this.http.post(`${BASE_URI}/validate/2BrM45vvfS/api`, { host });
    }
    authorizeCrupdate(params) {
        return this.http.post(`${BASE_URI}/authorize/store`, params);
    }
}
CustomDomainService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomDomainService_Factory() { return new CustomDomainService(i0.ɵɵinject(i1.AppHttpClient)); }, token: CustomDomainService, providedIn: "root" });
