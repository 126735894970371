import { AppHttpClient } from 'common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class Projects {
    constructor(http) {
        this.http = http;
    }
    /**
     * Get all available projects.
     */
    all(params) {
        return this.http.get('projects', params);
    }
    /**
     * Get project matching specified id.
     */
    get(id) {
        return this.http.get('projects/' + id);
    }
    /**
     * Create a new project.
     */
    create(params) {
        return this.http.post('projects', params);
    }
    /**
     * Update project matching specified id.
     */
    update(id, params) {
        return this.http.put('projects/' + id, params);
    }
    /**
     * Delete project matching specified id.
     */
    delete(params) {
        return this.http.delete('projects', params);
    }
    /**
     * Create or update specified project's thumbnail image.
     */
    generateThumbnail(projectId, dataUrl) {
        return this.http.post('projects/' + projectId + '/generate-thumbnail', { dataUrl });
    }
    /**
     * Publish specified project to FTP.
     */
    publish(projectId, params) {
        return this.http.post('projects/' + projectId + '/publish', params);
    }
}
Projects.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Projects_Factory() { return new Projects(i0.ɵɵinject(i1.AppHttpClient)); }, token: Projects, providedIn: "root" });
