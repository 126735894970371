import { ComponentFactoryResolver, ElementRef, Injector, NgZone } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { filter } from 'rxjs/operators';
import { ContextMenuRef } from './context-menu-ref';
import { CONTEXT_MENU_DATA } from './context-menu-data';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/cdk/overlay";
const ORIGIN_ID = 'dynamic-context-menu-origin';
export class ContextMenu {
    constructor(router, zone, injector, resolver, overlay) {
        this.router = router;
        this.zone = zone;
        this.injector = injector;
        this.resolver = resolver;
        this.overlay = overlay;
        this.router.events
            .pipe(filter(e => e instanceof NavigationStart))
            .subscribe(() => this.close());
    }
    open(component, e, params = {}) {
        this.close();
        if (!component) {
            return;
        }
        const overlayRef = this.createOverlay(e, params);
        this.openContextMenu = new ContextMenuRef(overlayRef);
        const injector = new PortalInjector(this.injector, new WeakMap([
            [CONTEXT_MENU_DATA, params.data],
            [ContextMenuRef, this.openContextMenu],
        ]));
        const portal = new ComponentPortal(component, null, injector);
        overlayRef.attach(portal);
        this.bindEventsToOverlay();
        return this.openContextMenu;
    }
    /**
     * Close currently open context menu.
     */
    close() {
        this.removeLastOrigin();
        if (this.openContextMenu) {
            this.openContextMenu.close();
            this.openContextMenu = null;
        }
    }
    createOverlay(e, params) {
        return this.overlay.create({
            positionStrategy: this.getMenuPositionStrategy(e, params),
            scrollStrategy: this.overlay.scrollStrategies.close(),
            hasBackdrop: true,
            backdropClass: 'context-menu-backdrop',
            panelClass: 'context-menu-overlay'
        });
    }
    bindEventsToOverlay() {
        this.openContextMenu.contextMenuElement().addEventListener('click', () => {
            this.close();
        });
        this.openContextMenu.backdropClick().subscribe(() => {
            this.close();
        });
        this.openContextMenu.backdropElement().addEventListener('contextmenu', e => {
            e.preventDefault();
            this.close();
        });
        this.openContextMenu.detachments().subscribe(() => {
            this.close();
        });
    }
    getMenuPositionStrategy(e, params) {
        this.createOriginFromEvent(e, params);
        const primary = {
            originX: params.originX || 'center',
            originY: params.originY || 'bottom',
            overlayX: params.overlayX || 'center',
            overlayY: params.overlayY || 'top',
        };
        return this.overlay.position().flexibleConnectedTo(new ElementRef(this.lastOrigin))
            .withPositions([
            primary,
            { originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'bottom' },
            { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
            { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'bottom' }
        ]);
    }
    createOriginFromEvent(e, params) {
        this.removeLastOrigin();
        if (e instanceof HTMLElement || e instanceof EventTarget) {
            this.lastOrigin = e;
        }
        else {
            this.lastOrigin = document.createElement('div');
            this.lastOrigin.style.position = 'fixed';
            this.lastOrigin.style.top = e.clientY + (params.offsetY || 0) + 'px';
            this.lastOrigin.style.left = e.clientX + (params.offsetX || 0) + 'px';
            this.lastOrigin.id = ORIGIN_ID;
            document.body.appendChild(this.lastOrigin);
        }
    }
    removeLastOrigin() {
        if (this.lastOrigin && this.lastOrigin.id === ORIGIN_ID) {
            this.lastOrigin.remove();
        }
    }
}
ContextMenu.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContextMenu_Factory() { return new ContextMenu(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i2.Overlay)); }, token: ContextMenu, providedIn: "root" });
