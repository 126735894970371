import { Settings } from 'common/core/config/settings.service';
import { CurrentUser } from 'common/auth/current-user';
import { slugifyString } from '@common/core/utils/slugify-string';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/config/settings.service";
import * as i2 from "../../../common/auth/current-user";
export class ProjectUrl {
    constructor(settings, currentUser) {
        this.settings = settings;
        this.currentUser = currentUser;
    }
    /**
     * Get specified project's base url for the builder.
     */
    getBaseUrl(project, relative = false) {
        const uri = 'projects/' + this.getProjectUserId(project) + '/' + project.uuid + '/';
        // if (relative) return uri;
        if (relative)
            return uri;
        return this.settings.getBaseUrl() + 'storage/' + uri;
    }
    /**
     * Get ID of specified project's creator.
     */
    getProjectUserId(project) {
        if (!project.users || !project.users.length) {
            return this.currentUser.get('id');
        }
        return project.users[0].id;
    }
    /**
     * Get production site url for specified project.
     */
    getSiteUrl(project) {
        const base = this.settings.getBaseUrl(true);
        const protocol = base.match(/(^\w+:|^)\/\//)[0];
        const projectName = slugifyString(project.name);
        let url;
        if (project.domain) {
            return project.domain.host;
        }
        else if (project.domain || this.settings.get('builder.enable_subdomains')) {
            // strip protocol from the url
            url = protocol + projectName + '.' + base.replace(protocol, '');
        }
        else {
            url = base + 'sites/' + projectName;
        }
        return url.replace(/\/$/, '');
    }
}
ProjectUrl.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectUrl_Factory() { return new ProjectUrl(i0.ɵɵinject(i1.Settings), i0.ɵɵinject(i2.CurrentUser)); }, token: ProjectUrl, providedIn: "root" });
