import { Router } from '@angular/router';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AppHttpClient } from '@common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../core/http/app-http-client.service";
export class Paginator {
    constructor(router, http) {
        this.router = router;
        this.http = http;
        this.params$ = new BehaviorSubject({});
        this.lastResponse$ = new ReplaySubject(1);
        this.initiated = false;
        this.paginatedOnce$ = new BehaviorSubject(false);
        // might not want to update query params sometimes
        // if data table is only smaller part of the page
        this.dontUpdateQueryParams = false;
        this.loading$ = new BehaviorSubject(false);
    }
    get pagination$() {
        return this.lastResponse$.asObservable();
    }
    get noResults$() {
        // only return TRUE if data has already been
        // loaded from backend and there were no results
        return this.pagination$.pipe(map(p => !!p.data && p.data.length === 0));
    }
    paginate(userParams = {}, url, initialData) {
        const queryParams = this.router.routerState.root.snapshot.queryParams;
        this.params$.next(Object.assign({}, queryParams, userParams));
        if (!this.initiated) {
            this.init(url, initialData);
        }
        // prevent multiple subscriptions
        return this.pagination$.pipe(take(1));
    }
    init(uri, initialData) {
        this.backendUri = uri;
        this.subscription = this.params$.pipe(switchMap(params => {
            this.loading$.next(true);
            // if we got initial pagination response (of 1st page)
            // return that instead of making 1st page http request
            const request = !this.paginatedOnce$.value && initialData ?
                of({ pagination: initialData }) :
                this.http.get(this.backendUri, params);
            return request.pipe(
            // can't use "finalize" here as it will complete after loading$.next(true)
            // call above, which will prevent loading bar from showing
            // if pagination request is cancelled and new one is queued
            tap(() => {
                this.updateQueryParams(params);
                this.loading$.next(false);
                this.paginatedOnce$.next(true);
            }, () => {
                this.loading$.next(false);
                this.paginatedOnce$.next(true);
            }));
        })).subscribe(response => {
            this.lastResponse$.next(response.pagination);
        });
        this.initiated = true;
    }
    updateQueryParams(params = {}) {
        if (this.dontUpdateQueryParams)
            return;
        this.router.navigate([], { queryParams: params, replaceUrl: true });
    }
    destroy() {
        this.subscription && this.subscription.unsubscribe();
    }
}
Paginator.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Paginator_Factory() { return new Paginator(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AppHttpClient)); }, token: Paginator, providedIn: "root" });
