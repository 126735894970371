import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard.component';
import {NewProjectPageComponent} from './new-project-page/new-project-page.component';
import {TemplatesResolver} from './new-project-page/templates-resolver.service';
import {DashboardResolver} from './dashboard-resolver.server';
import {NOT_FOUND_ROUTES} from '@common/core/pages/not-found-routes';

const routes: Routes = [
    {path: 'dashboard', component: DashboardComponent, resolve: {api: DashboardResolver}},
    {path: 'dashboard/projects', redirectTo: 'dashboard', pathMatch: 'full'},
    {path: 'dashboard/projects/new', component: NewProjectPageComponent, resolve: {templates: TemplatesResolver}},
    ...NOT_FOUND_ROUTES,
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule {
}
