import { MatPaginatorIntl } from '@angular/material/paginator';
import { Translations } from '@common/core/translations/translations.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/translations/translations.service";
export class DataTableIntlService extends MatPaginatorIntl {
    constructor(i18n) {
        super();
        this.i18n = i18n;
        this.itemsPerPageLabel = this.i18n.t('Items per page');
        this.nextPageLabel = this.i18n.t('Next page');
        this.previousPageLabel = this.i18n.t('Previous page');
        this.getRangeLabel = (page, pageSize, length) => {
            const of = this.i18n.t('of');
            if (length == 0 || pageSize == 0) {
                return `0 ${of} ${length}`;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            // If the start index exceeds the list length, do not try and fix the end index to the end.
            const endIndex = startIndex < length ?
                Math.min(startIndex + pageSize, length) :
                startIndex + pageSize;
            return `${startIndex + 1} - ${endIndex} ${of} ${length}`;
        };
    }
}
DataTableIntlService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataTableIntlService_Factory() { return new DataTableIntlService(i0.ɵɵinject(i1.Translations)); }, token: DataTableIntlService, providedIn: "root" });
