import { Router } from '@angular/router';
import { Templates } from '../../shared/templates/templates.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../shared/templates/templates.service";
export class TemplatesResolver {
    constructor(router, templates) {
        this.router = router;
        this.templates = templates;
    }
    resolve(route, state) {
        return this.templates.all({ per_page: 25 }).pipe(catchError(() => {
            this.router.navigate(['/dashboard']);
            return EMPTY;
        }), mergeMap(response => {
            if (!response) {
                this.router.navigate(['/dashboard']);
                return EMPTY;
            }
            else {
                return of(response.pagination);
            }
        }));
    }
}
TemplatesResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TemplatesResolver_Factory() { return new TemplatesResolver(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Templates)); }, token: TemplatesResolver, providedIn: "root" });
