import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UiModule} from 'common/core/ui/ui.module';
import {MaterialModule} from '../material.module';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {SharedModule} from '../shared/shared.module';
import {DashboardComponent} from './dashboard.component';
import {NewProjectPageComponent} from './new-project-page/new-project-page.component';
import {TemplatesInfiniteScrollDirective} from './new-project-page/templates-infinite-scroll.directive';
import {MatTabsModule} from '@angular/material';
import {CustomDomainModule} from '@common/custom-domain/custom-domain.module';
import {LandingComponent} from './landing/landing.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UiModule,
        DashboardRoutingModule,
        SharedModule,
        CustomDomainModule,

        MaterialModule,
        MatTabsModule,
    ],
    declarations: [
        DashboardComponent,
        NewProjectPageComponent,
        TemplatesInfiniteScrollDirective,
        LandingComponent,
    ],
})
export class DashboardModule {
}
